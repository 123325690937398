import React from "react";

const Header = () => {
  return (
    <header>
      <div>
        <a href="/">DEV DOCUMENTATION</a>
      </div>
    </header>
  )
}

export default Header;